import { Box } from "@chakra-ui/layout";
import React from "react";
import Geosuggest from "react-geosuggest";
import { CherryPayApi } from "../../../api/models";
import { BaseFieldProps, FieldWrapper } from "../FieldWrapper/FieldWrapper";

interface GeocodedAddressProps extends BaseFieldProps {
  country: string | string[] | undefined;
  placeholder?: string | undefined;
}

export function GeocodedAddress({
  name,
  label,
  id,
  helperText,
  infoText,
  country,
  placeholder,
}: GeocodedAddressProps) {
  const validateAddress = (
    providedValue: CherryPayApi.GeocodedAddress | null
  ): string | boolean => {
    if (!providedValue) {
      return true;
    }

    const addressComponent = providedValue.address_components;

    const validComponents = new Map([
      ["country", true],
      ["post code", true],
      ["state", true],
      ["locality", true],
      ["route", true],
    ]);

    const country = addressComponent.find((component: any) =>
      component.types.includes("country")
    );

    const postalCode = addressComponent.find((component: any) =>
      component.types.includes("postal_code")
    );

    const administrativeAreaLevel1 = addressComponent.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );

    const locality = addressComponent.find(
      (component: any) =>
        component.types.includes("locality") ||
        component.types.includes("postal_town")
    );

    const route = addressComponent.find((component: any) =>
      component.types.includes("route")
    );

    validComponents.set("country", country !== null && country !== undefined);
    validComponents.set(
      "post code",
      postalCode !== null && postalCode !== undefined
    );
    validComponents.set(
      "state",
      administrativeAreaLevel1 !== null &&
        administrativeAreaLevel1 !== undefined
    );
    validComponents.set(
      "locality",
      locality !== null && locality !== undefined
    );
    validComponents.set("route", route !== null && route !== undefined);

    // construct error message
    const baseErrorMessage = "You must select an address which includes a";
    let errorMessage: string = "";
    const hasError = Array.from(validComponents.values()).some(
      (v) => v === false
    );
    if (hasError) {
      errorMessage = baseErrorMessage;
    } else {
      return true;
    }
    validComponents.forEach((value, key) => {
      if (!value) {
        if (errorMessage.length === baseErrorMessage.length) {
          errorMessage = `${errorMessage} ${key}`;
        } else {
          errorMessage = `${errorMessage}, ${key}`;
        }
      }
    });
    return errorMessage;
  };

  return (
    <FieldWrapper
      name={name}
      id={id}
      label={label}
      helperText={helperText}
      infoText={infoText}
    >
      {({ field, form }) => {
        return (
          <Box w="100%">
            <Geosuggest
              width="100%"
              types={["address"]}
              onSuggestSelect={(val: any) => {
                let value: CherryPayApi.GeocodedAddress | null;

                if (val) {
                  value = {
                    address_components: val?.gmaps?.address_components,
                    formatted_address: val?.gmaps?.formatted_address,
                    geometry: val?.gmaps?.geometry,
                  };
                  if (value.geometry) {
                    delete (value.geometry as any)?.viewport;
                  }
                } else {
                  value = null;
                }

                var result = validateAddress(value);

                if (result === true) {
                  form.setFieldValue(field.name, value, true);
                } else {
                  form.setFieldError(field.name, result as string);
                }
              }}
              autoFocus
              country={country}
              placeholder={placeholder}
            />
          </Box>
        );
      }}
    </FieldWrapper>
  );
}
