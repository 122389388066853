export enum AddressType {
  Home = "Home",
  Work = "Work",
  Postal = "Postal",
}

export const AddressTypeList = () =>
  Object.entries(AddressType).map(([label, value]) => {
    return { label: value, value: label };
  });
