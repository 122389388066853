import React from "react";
import { Icon, IconProps } from "@chakra-ui/icons";
import {
  FaCircle,
  FaCog,
  FaCreditCard,
  FaGift,
  FaList,
  FaMicrosoft,
  FaUserFriends,
  FaUserLock,
} from "react-icons/fa";
import { FaBan } from "react-icons/fa6";
import {
  FiUser,
  FiUserPlus,
  FiLock,
  FiMoreHorizontal,
  FiPlus,
  FiLogOut,
  FiX,
  FiCheck,
  FiTrash2,
  FiEdit,
  FiRefreshCw,
  FiCreditCard,
  FiSmartphone,
  FiRepeat,
  FiZap,
  FiCheckCircle,
  FiXCircle,
  FiInfo,
  FiFileText,
  FiSearch,
  FiBook,
  FiBookOpen,
} from "react-icons/fi";
import { RiShareBoxFill } from "react-icons/ri";
import { TbReplaceFilled } from "react-icons/tb";
import { IconType } from "react-icons/lib";
import { GoQuestion } from "react-icons/go";
import { MdOutlineSettings } from "react-icons/md";

/**
 * Wraps a non Chakra icon in a <Icon/> component
 * @param icon
 * @returns
 */
const wrapIcon = (icon: IconType) => {
  return (props: IconProps) => <Icon {...props} as={icon} />;
};

export const CogIcon = wrapIcon(FaCog);

export const CircleIcon = wrapIcon(FaCircle);

export const ListIcon = wrapIcon(FaList);

export const UserIcon = wrapIcon(FiUser);

export const UsersIcon = wrapIcon(FaUserFriends);

export const UserPlusIcon = wrapIcon(FiUserPlus);

export const UserLockIcon = wrapIcon(FaUserLock);

export const LockIcon = wrapIcon(FiLock);

export const MoreHorizontal = wrapIcon(FiMoreHorizontal);

export const PlusIcon = wrapIcon(FiPlus);

export const LogoutIcon = wrapIcon(FiLogOut);

export const DeleteIcon = wrapIcon(FiX);

export const EditIcon = wrapIcon(FiEdit);

export const CheckIcon = wrapIcon(FiCheck);

export const TrashIcon = wrapIcon(FiTrash2);

export const RefreshIcon = wrapIcon(FiRefreshCw);

export const CardIcon = wrapIcon(FiCreditCard);

export const PhoneIcon = wrapIcon(FiSmartphone);

export const TransferIcon = wrapIcon(FiRepeat);

export const ZapIcon = wrapIcon(FiZap);

export const CheckCircleIcon = wrapIcon(FiCheckCircle);

export const XCircleIcon = wrapIcon(FiXCircle);

export const InfoIcon = wrapIcon(FiInfo);

export const FileIcon = wrapIcon(FiFileText);

export const CreditCardIcon = wrapIcon(FaCreditCard);

export const GiftCardIcon = wrapIcon(FaGift);

export const BoxShareIcon = wrapIcon(RiShareBoxFill);

export const SearchIcon = wrapIcon(FiSearch);

export const BookIcon = wrapIcon(FiBook);

export const RenewalIcon = wrapIcon(TbReplaceFilled);

export const CancelIcon = wrapIcon(FaBan);

export const BookOpenIcon = wrapIcon(FiBookOpen);

export const MicrosoftIcon = wrapIcon(FaMicrosoft);

export const QuestionIcon = wrapIcon(GoQuestion);

export const SettingsIcon = wrapIcon(MdOutlineSettings);
