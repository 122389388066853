import { Box, Center, Text, VStack } from "@chakra-ui/layout";
import {
  Tag,
  Button,
  IconButton,
  HStack,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/table";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/menu";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "../Card/Card";
import { SearchInput } from "../SearchInput/SearchInput";
import { useSearchInputState } from "../../hooks/useSearchInputState";
import { usePaginatedApiRequest } from "../../hooks/usePaginatedApiRequest";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { useModalState } from "../../hooks/useModalState";
import { PointsRequestModal } from "../../components/PointsRequestModal/PointsRequestModal";
import {
  CardIcon,
  MoreHorizontal,
  PlusIcon,
  TransferIcon,
  TrashIcon,
  UserIcon,
  UserPlusIcon,
} from "../../styles/icons";
import { InvitationModal } from "../InvitationModal/InvitationModal";
import { usePermissions } from "../../hooks/usePermissions";
import { TransferPointsModal } from "../TransferPointsModal/TransferPointsModal";
import { CherryPayCardModal } from "../CherryPayCardModal/CherryPayCardModal";
import { MemberFilterQueryModal } from "../MemberFilterQueryModal/MemberFilterQueryModal";
import DateUtil from "../../util/DateUtil";
import { emptyStrToNull } from "../../util/StringUtil";
import { CherryPayApi } from "../../api/models";
import { EditIcon } from "@chakra-ui/icons";
import { useConfirmationDialog } from "../../hooks/useConfirmationDialog";
import { useApiUpdateRequest } from "../../hooks/useApiUpdateRequest";
import { useBusinessContext } from "../../context/ModelContext";

export const Members = ({ businessId }: { businessId: string }) => {
  const { query, debouncedQuery, onChange, isDebouncing, setQuery } =
    useSearchInputState("");

  const [
    canAwardPoints,
    canInviteMemberToCard,
    canInviteMemberToApp,
    canTransferPointsToMoney,
  ] = usePermissions(
    "Loyalty.CreditPoints",
    "Member.InviteToCard",
    "Member.InviteToApp",
    "Cardholder.PointsToMoney"
  );

  const [filterQuery, setFilterQuery] = useState<string>();
  const [resetBtn, setResetBtn] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    items: members,
    isLoading,
    isLoadingPage,
    error,
    fetchNextPage,
  } = usePaginatedApiRequest(
    // Request the first page
    (apiClient) =>
      apiClient.searchMembersQuery(businessId, debouncedQuery, filterQuery),
    // Request subsequent pages
    (apiClient, continuationToken) =>
      apiClient.searchMembersQuery(
        businessId,
        debouncedQuery,
        filterQuery,
        continuationToken
      ),
    [businessId, debouncedQuery, filterQuery]
  );

  const business = useBusinessContext();
  const addPointsModalState = useModalState<{
    memberId: string;
    type: "add-points" | "transfer-points-to-cpc";
  }>();
  const invitationModalState = useModalState<{
    memberId: string;
    type: "digital-membership" | "cherry-pay-card";
    cpcType?: "instantgift" | "reloadable";
  }>();
  const { confirmationElement, showConfirmationDialog } =
    useConfirmationDialog();

  const deleteMemberRequest = useApiUpdateRequest(
    (apiClient, memberId: string) =>
      apiClient.deleteHubMembershipMember(businessId, memberId),
    [businessId]
  );

  const onDeleteMember = useCallback(
    (member: CherryPayApi.Member) => {
      showConfirmationDialog(
        "Delete Member?",
        "Are you sure you wish to delete this member?",
        "Delete"
      ).then(({ ok }) => {
        if (ok) {
          deleteMemberRequest.request(member.id);
        }
      });
    },
    [deleteMemberRequest.request, showConfirmationDialog]
  );

  const memberFilterQueryModal = useDisclosure();
  const onSuccessMemberFilterQuery = (query: string | undefined) => {
    setFilterQuery(query);
    memberFilterQueryModal.onClose();
  };

  useEffect(() => {
    if (filterQuery) {
      setResetBtn(true);
    } else {
      setResetBtn(false);
    }
  }, [filterQuery]);

  const filterReset = () => {
    setFilterQuery(undefined);
  };

  const memberRows = useMemo(
    () =>
      members?.map((member) => {
        const memberId = member.id;
        const memberLink = `/businesses/${businessId}/members/${memberId}`;
        return (
          <Tr key={memberId}>
            <Td>
              <Link to={memberLink}>
                {emptyStrToNull(member.MembershipNumber) ??
                  emptyStrToNull(member.BadgeNumber) ??
                  "-"}
              </Link>
            </Td>
            <Td>
              <Link to={memberLink}>
                {(member.FirstName + " " + member.LastName).trim() == ""
                  ? "-"
                  : member.FirstName + " " + member.LastName}
              </Link>
            </Td>
            <Td>
              {DateUtil.formatDate(member.BirthDate, "en-AU", {
                dateStyle: "medium",
              }) ?? "-"}
            </Td>
            <Td>{member.Status ?? "-"}</Td>
            <Td>
              {DateUtil.formatDate(member.DateFinancial, "en-AU", {
                dateStyle: "medium",
              }) ?? "-"}
            </Td>
            <Td>{member.MemberLevelDescription ?? "-"}</Td>
            <Td>{member.MembershipTypeDescription ?? "-"}</Td>
            <Td width="0.1%" whiteSpace="nowrap">
              <Menu>
                <MenuButton
                  icon={<MoreHorizontal />}
                  variant="ghost"
                  as={IconButton}
                  size="sm"
                  w="100%"
                  fontSize="sm"
                  aria-label="Member Options"
                ></MenuButton>
                <MenuList>
                  <MenuItem as={Link} to={memberLink} icon={<UserIcon />}>
                    View Member
                  </MenuItem>
                  {business.LicencedCherryHubProducts?.includes(
                    "HubMembership"
                  ) && (
                    <MenuItem
                      onClick={() => onDeleteMember(member)}
                      icon={<TrashIcon />}
                    >
                      Delete Member
                    </MenuItem>
                  )}
                  {canInviteMemberToApp && (
                    <MenuItem
                      onClick={() =>
                        invitationModalState.onOpen({
                          memberId,
                          type: "digital-membership",
                        })
                      }
                      icon={<CardIcon />}
                    >
                      Digital Membership Card
                    </MenuItem>
                  )}
                  {canInviteMemberToCard && (
                    <>
                      <MenuItem
                        onClick={() =>
                          invitationModalState.onOpen({
                            memberId,
                            type: "cherry-pay-card",
                            cpcType: "instantgift",
                          })
                        }
                        icon={<CardIcon />}
                      >
                        CherryPay Instant Gift Card
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          invitationModalState.onOpen({
                            memberId,
                            type: "cherry-pay-card",
                            cpcType: "reloadable",
                          })
                        }
                        icon={<CardIcon />}
                      >
                        CherryPay Reloadable Card
                      </MenuItem>
                    </>
                  )}
                  {canTransferPointsToMoney && (
                    <MenuItem
                      onClick={() =>
                        addPointsModalState.onOpen({
                          memberId,
                          type: "transfer-points-to-cpc",
                        })
                      }
                      icon={<TransferIcon />}
                    >
                      Transfer Points
                    </MenuItem>
                  )}
                  {canAwardPoints && (
                    <MenuItem
                      onClick={() =>
                        addPointsModalState.onOpen({
                          memberId,
                          type: "add-points",
                        })
                      }
                      icon={<PlusIcon />}
                    >
                      Award Points
                    </MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Td>
          </Tr>
        );
      }),
    [members]
  );

  return (
    <>
      <VStack width="100%" spacing="2">
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            <HStack width="100%">
              <HStack width="100%" flex="1">
                <Box maxWidth="300px">
                  <SearchInput
                    value={query}
                    onChange={onChange}
                    isLoading={isLoading || isDebouncing}
                    placeholder="Search members..."
                    setQuery={setQuery}
                  />
                </Box>
                <Button
                  variant="solid"
                  colorScheme="cherryButton"
                  color="#fff"
                  onClick={() => memberFilterQueryModal.onOpen()}
                >
                  Filters
                </Button>
                {resetBtn && <Button onClick={filterReset}>Reset</Button>}
                {!members && isLoading && (
                  <Center>
                    <Spinner margin="4" />
                  </Center>
                )}
              </HStack>
              {business.LicencedCherryHubProducts?.includes(
                "HubMembership"
              ) && (
                <Button
                  leftIcon={<UserPlusIcon />}
                  onClick={() =>
                    navigate(`/businesses/${businessId}/members/new`)
                  }
                  alignSelf="end"
                  colorScheme="cherryButton"
                >
                  New Member
                </Button>
              )}
            </HStack>

            {members && members.length > 0 && (
              <Box w="100%" overflowX="auto">
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Membership No</Th>
                      <Th>Name</Th>
                      <Th>Birth Date</Th>
                      <Th>Status</Th>
                      <Th>Financial Date</Th>
                      <Th>Level</Th>
                      <Th>Membership Type</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>{memberRows}</Tbody>
                </Table>
              </Box>
            )}
            {!isLoading && members && members.length === 0 && (
              <Text>
                {debouncedQuery === ""
                  ? "No members found."
                  : `No members found matching query "${debouncedQuery}".`}
              </Text>
            )}

            {fetchNextPage && (
              <Box>
                {!isLoadingPage && (
                  <Button colorScheme="cherryButton" onClick={fetchNextPage}>
                    Load more...
                  </Button>
                )}
                {isLoadingPage && <Spinner />}
              </Box>
            )}

            {error != null && (
              <ErrorMessage>
                An error was encountered while searching members.
              </ErrorMessage>
            )}
          </VStack>
        </Card>
      </VStack>
      <TransferPointsModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "transfer-points-to-cpc"
        }
        memberId={addPointsModalState.opts?.memberId}
        onClose={addPointsModalState.onClose}
      />
      <PointsRequestModal
        isOpen={
          addPointsModalState.isOpen &&
          addPointsModalState.opts?.type === "add-points"
        }
        memberId={addPointsModalState.opts?.memberId}
        pointsRequestType="add-points"
        onClose={addPointsModalState.onClose}
      />
      <InvitationModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "digital-membership"
        }
        memberId={invitationModalState.opts?.memberId}
        invitationType={invitationModalState.opts?.type}
        onClose={invitationModalState.onClose}
      />
      <CherryPayCardModal
        isOpen={
          invitationModalState.isOpen &&
          invitationModalState.opts?.type === "cherry-pay-card"
        }
        cpcType={invitationModalState.opts?.cpcType ?? "instantgift"}
        memberId={invitationModalState.opts?.memberId}
        onClose={invitationModalState.onClose}
      />
      <MemberFilterQueryModal
        size="4xl"
        {...memberFilterQueryModal}
        onSuccess={onSuccessMemberFilterQuery}
        businessId={businessId}
        memberQueryFilter={filterQuery ?? ""}
      />
    </>
  );
};
