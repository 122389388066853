import { Heading, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { CherryPayApi } from "../../api/models";
import { useMemberContext } from "../../context/ModelContext";
import { CheckCircleIcon, XCircleIcon } from "../../styles/icons";
import { ViewControl } from "../ViewControl/ViewControl";

const ContactDetails = ({
  heading,
  contactDetail,
}: {
  heading: string;
  contactDetail?: CherryPayApi.Contact;
}) => {
  return (
    <VStack align="start" w="100%">
      <Heading variant="view-heading">{heading}</Heading>
      <VStack align="start" w="100%" spacing={6} pb={10}>
        <ViewControl
          label="Phone Number:"
          value={contactDetail?.PhoneNumber ?? "-"}
        />

        <ViewControl
          label="Mobile Number:"
          value={contactDetail?.MobileNumber ?? "-"}
        />

        <ViewControl
          label="Email:"
          value={contactDetail?.EmailAddress ?? "-"}
        />

        <ViewControl
          label="Accepts Phone Marketing:"
          value={
            contactDetail?.AcceptsPhoneMarketing ? (
              <CheckCircleIcon color="whatsapp.500" />
            ) : (
              <XCircleIcon color="red" />
            )
          }
        />

        <ViewControl
          label="Accepts Mobile Marketing:"
          value={
            contactDetail?.AcceptsMobileMarketing ? (
              <CheckCircleIcon color="whatsapp.500" />
            ) : (
              <XCircleIcon color="red" />
            )
          }
        />

        <ViewControl
          label="Accepts Email Marketing:"
          value={
            contactDetail?.AcceptsEmailMarketing ? (
              <CheckCircleIcon color="whatsapp.500" />
            ) : (
              <XCircleIcon color="red" />
            )
          }
        />
      </VStack>
    </VStack>
  );
};

export const MemberContactCards = ({
  member,
}: {
  member: CherryPayApi.Member;
}) => {
  return (
    <Stack w="100%" align="start" direction={["column", "row"]} spacing={2}>
      <ContactDetails
        contactDetail={member.HomeContactDetails}
        heading="Home Contact"
      />

      <ContactDetails
        contactDetail={member.WorkContactDetails}
        heading="Work Contact"
      />

      <ContactDetails
        contactDetail={member.PostalContactDetails}
        heading="Postal Contact"
      />
    </Stack>
  );
};
