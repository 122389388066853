import { CheckIcon } from "@chakra-ui/icons";
import {
  Input,
  InputProps,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/input";
import React from "react";
import {
  BaseFieldProps,
  FieldWrapper,
} from "../fields/FieldWrapper/FieldWrapper";

interface DateFieldProps extends BaseFieldProps {
  isValid?: boolean;
}

export const DateField = ({
  name,
  label,
  id,
  helperText,
  isValid,
  infoText,
  ...inputProps
}: DateFieldProps & InputProps) => {
  const formatISODate = (isoDate: string): string => {
    const date = new Date(isoDate);
    if (!isNaN(date.getTime())) {
      return date.toISOString().split("T")[0];
    }
    return "";
  };

  return (
    <FieldWrapper
      name={name}
      id={id}
      label={label}
      helperText={helperText}
      infoText={infoText}
    >
      {({ field, form }) => {
        return (
          <InputGroup backgroundColor="#fff" shadow="sm">
            <Input
              {...field}
              value={field.value == null ? "" : formatISODate(field.value)}
              {...inputProps}
              type="date"
              onChange={(e) => {
                const value = e.target.value;
                form.setFieldValue(
                  name,
                  value ? new Date(value).toISOString() : ""
                );
              }}
            />
            {isValid && (
              <InputRightElement children={<CheckIcon color="green.400" />} />
            )}
          </InputGroup>
        );
      }}
    </FieldWrapper>
  );
};
