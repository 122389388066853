import React, { useCallback, useEffect } from "react";
import {
  Route,
  Routes,
  Outlet,
  useNavigate,
  useLocation,
  useParams,
  Navigate,
} from "react-router-dom";
import { Box, VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";

import { useUserContext } from "./context/UserContext";
import { Login } from "./pages/Login/Login";
import { Users } from "./pages/Users/Users";
import { MemberDetail } from "./pages/MemberDetail/MemberDetail";
import { Business } from "./pages/Business/Business";
import { BusinessList } from "./pages/BusinessList/BusinessList";

import { Layout } from "./components/Layout/Layout";
import { CurrentUser } from "./components/CurrentUser/CurrentUser";
import { AppMenu } from "./components/AppMenu/AppMenu";
import { AppTitle } from "./components/AppTitle/AppTitle";
import { ModelContextProvider } from "./context/ModelContext";
import { ErrorHandler } from "./components/ErrorBoundary/ErrorBoundary";
import { HStack } from "@chakra-ui/react";
import { BusinessRoles } from "./pages/BusinessRoles/BusinessRoles";
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";

import cherryLogo from "assets/cherryhub_logo.svg";
import { BusinessConfig } from "./pages/BusinessConfig/BusinessConfig";
import { useLayoutContext } from "./context/LayoutContext";
import { IssueCards } from "./pages/Cards/IssueCards";
import { PassEditor } from "./pages/PassEditor/PassEditor";
import { Reports } from "./pages/Reports/Reports";
import { CherryPayCardsByMember } from "./pages/Reports/CherryPayCardsByMember";
import { OrderPhysicalCard } from "./pages/OrderPhysicalCard/OrderPhysicalCard";
import { CherryPayDisbursementAccTransactions } from "./pages/Reports/CherryPayDisbursementAccTransactions";
import { CardDetail } from "./pages/Cards/CardDetail";
import { MembershipRequest } from "./pages/MembershipRequest/MembershipRequest";
import { MembershipRequestDetail } from "./pages/MembershipRequest/MembershipRequestDetail";
import { CherryPayMembershipApplication } from "./pages/Reports/CherryPayMembershipApplication";
import { CherryPayPassesByMember } from "./pages/Reports/CherryPayPassesByMember";
import { MembershipRenewalRequest } from "./pages/MembershipRenewalRequest/MembershipRenewalRequest";
import { CherryPayMembershipRenewalApplication } from "./pages/Reports/CherryPayMembershipRenewalApplication";
import { MemberList } from "./pages/MemberList/MemberList";
import { BusinessDashboard } from "./pages/Business/BusinessDashboard";
import { AppSwitcher } from "./components/AppSwitcher/AppSwitcher";

const AppLayout = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { isDesktop } = useLayoutContext();
  const paths = location.pathname.split("/");
  const hasBusinessContext = paths.length >= 4 && paths[3];

  return (
    <ModelContextProvider>
      <Layout location={location.pathname}>
        <Layout.Title>
          <HStack>
            <AppTitle />
            {isDesktop && (
              <>
                {/* {hasBusinessContext && <AppSwitcher />} */}
                <CurrentUser />
              </>
            )}
          </HStack>
        </Layout.Title>
        <Layout.Menu>
          {!isDesktop && (
            <VStack w="100%" bg="cherryUi.600" padding="2" marginBottom="2">
              <Image src={cherryLogo} boxSize="100px" title="Chery hub" />
              <CurrentUser />
            </VStack>
          )}
          {!!user && (
            <VStack spacing="2" w="100%">
              <AppMenu isDesktop={isDesktop} />
            </VStack>
          )}
        </Layout.Menu>
        <Layout.Content>
          <ErrorHandler
            renderErrorState={() => (
              <ErrorMessage>There was an error loading the page.</ErrorMessage>
            )}
          >
            <Outlet />
          </ErrorHandler>
        </Layout.Content>
      </Layout>
    </ModelContextProvider>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, userBusinessId, isLoading } = useUserContext();
  const { businessId } = useParams();
  const navigate = useNavigate();

  const onLoginSuccess = useCallback(
    (businessId?: string) => {
      navigate(
        businessId !== "*" ? `/businesses/${businessId}` : "/businesses"
      );
    },
    [navigate]
  );

  // If the user is not authorised to view the current business, redirect them to their business.
  useEffect(() => {
    if (
      !isLoading &&
      userBusinessId &&
      userBusinessId !== "*" &&
      userBusinessId != businessId
    ) {
      navigate(`/businesses/${userBusinessId}`);
    }
  }, [businessId, userBusinessId, isLoading, navigate]);

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!user) {
    return <Login onLoginSuccess={onLoginSuccess} />;
  }

  return children;
};

const LoginRoute = () => {
  const navigate = useNavigate();
  const onLoginSuccess = useCallback(
    (businessId?: string) => {
      navigate(
        businessId !== "*" ? `/businesses/${businessId}` : "/businesses"
      );
    },
    [navigate]
  );
  return <Login onLoginSuccess={onLoginSuccess} />;
};

export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <RequireAuth>
          <AppLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Navigate to="/businesses" />} />
      <Route path="/businesses" element={<BusinessList />} />
      <Route path="/businesses/:businessId" element={<BusinessDashboard />} />
      <Route path="/businesses/:businessId/members" element={<MemberList />} />
      <Route
        path="/businesses/:businessId/members/:memberId"
        element={<MemberDetail />}
      />
      <Route
        path="/businesses/:businessId/members/new"
        element={<MemberDetail create={true} />}
      />
      <Route path="/businesses/:businessId/roles" element={<BusinessRoles />} />
      <Route path="/businesses/:businessId/users" element={<Users />} />
      <Route
        path="/businesses/:businessId/configuration"
        element={<BusinessConfig />}
      />
      <Route path="/businesses/:businessId/cards" element={<IssueCards />} />
      <Route
        path="/businesses/:businessId/pass-designer/:passConfigurationId"
        element={<PassEditor />}
      />

      <Route
        path="/businesses/:businessId/cards/order/:cardProgramId"
        element={<OrderPhysicalCard />}
      />

      <Route path="/businesses/:businessId/reports" element={<Reports />} />
      <Route
        path="/businesses/:businessId/reports/cherrypay-cards-by-member"
        element={<CherryPayCardsByMember />}
      />
      <Route
        path="/businesses/:businessId/reports/cherrypay-disbursement-account-transactions"
        element={<CherryPayDisbursementAccTransactions />}
      />
      <Route
        path="/businesses/:businessId/reports/membership-applications"
        element={<CherryPayMembershipApplication />}
      />
      <Route
        path="/businesses/:businessId/reports/passes-by-member"
        element={<CherryPayPassesByMember />}
      />
      <Route
        path="/businesses/:businessId/reports/membership-renewal-applications"
        element={<CherryPayMembershipRenewalApplication />}
      />

      <Route
        path="/businesses/:businessId/cards/:cardId"
        element={<CardDetail />}
      />

      <Route
        path="/businesses/:businessId/membership-requests"
        element={<MembershipRequest />}
      />
      <Route
        path="/businesses/:businessId/membership-requests/:sessionId"
        element={<MembershipRequestDetail />}
      />

      <Route
        path="/businesses/:businessId/membership-renewal-requests"
        element={<MembershipRenewalRequest />}
      />
    </Route>
    <Route path="/login" element={<LoginRoute />} />
  </Routes>
);
