import { Box, HStack, VStack, Text, Divider } from "@chakra-ui/react";
import React from "react";
import { SwitchField } from "../../components/fields/SwitchField/SwitchField";
import { TextField } from "../../components/fields/TextField/TextField";

interface MemberContactFormProps {
  contactFieldName: string;
  heading: string;
}

export const MemberContactForm = ({
  contactFieldName,
  heading,
}: MemberContactFormProps) => {
  return (
    <Box
      w="100%"
      p={4}
      border="1px solid"
      borderColor="cherryButtonDark.50"
      borderRadius="md"
    >
      <HStack width="100%" mb={2}>
        <Text fontSize="sm" flex="1">
          <b>{heading}</b> :
        </Text>
      </HStack>
      <Divider mb={2} />

      <VStack w="100%" alignItems="flex-start">
        <TextField
          name={`${contactFieldName}.EmailAddress`}
          label="Email Address"
          placeholder="Email Address"
        />
        <TextField
          name={`${contactFieldName}.MobileNumber`}
          label="Mobile Number"
          placeholder="Mobile Number"
        />
        <TextField
          name={`${contactFieldName}.PhoneNumber`}
          label="Phone Number"
          placeholder="Phone Number"
        />
        <SwitchField
          label="Accepts Phone Marketing"
          name={`${contactFieldName}.AcceptsPhoneMarketing`}
        />
        <SwitchField
          label="Accepts Mobile Marketing"
          name={`${contactFieldName}.AcceptsMobileMarketing`}
        />
        <SwitchField
          label="Accepts Email Marketing"
          name={`${contactFieldName}.AcceptsEmailMarketing`}
        />
      </VStack>
    </Box>
  );
};
