import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/form-control";
import {
  FormControlProps,
  StackProps,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { InfoIcon } from "../../../styles/icons";

export interface BaseFieldProps {
  name: string;
  label: string;
  helperText?: string;
  id?: string;
  infoText?: string;
  horizontal?: boolean;
  fcProps?: FormControlProps;
  valueProps?: StackProps;
}

export interface FieldWrapperProps extends BaseFieldProps {
  children: (props: FieldProps<any, any>) => JSX.Element;
}

const getNestedFormError = (errors: any, name: string) => {
  const nameParts = name.split(".");

  const error: string | undefined = nameParts.reduce((error, namePart) => {
    if (typeof error === "object" && error[namePart]) {
      return error[namePart];
    } else {
      if (typeof error === "string") {
        return error;
      } else {
        return undefined;
      }
    }
  }, errors);

  return error;
};

/** Provides label and error message rendering */
export const FieldWrapper = ({
  name,
  label,
  id: idProp,
  helperText,
  infoText,
  horizontal,
  fcProps,
  valueProps,
  children,
}: FieldWrapperProps) => {
  const id = idProp ?? `${name}-field`;
  return (
    <Field name={name}>
      {(fieldProps: FieldProps<any, any>) => (
        <FormControl
          isInvalid={!!getNestedFormError(fieldProps.form.errors, name)}
          display={horizontal ? "flex" : undefined}
          flexDir={horizontal ? "row" : undefined}
          {...fcProps}
        >
          <VStack
            w="100%"
            alignItems="flex-start"
            flex={horizontal ? 1 : undefined}
            pt={horizontal ? 2 : undefined}
          >
            {infoText && (
              <FormLabel htmlFor={id}>
                {label}{" "}
                <Tooltip label={infoText} placement="right">
                  <span>
                    <InfoIcon />
                  </span>
                </Tooltip>
              </FormLabel>
            )}
            {!infoText && <FormLabel htmlFor={id}>{label}</FormLabel>}
            {!!helperText && (
              <FormHelperText marginBottom="2">{helperText}</FormHelperText>
            )}
          </VStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            alignSelf="center"
            flex={horizontal ? 3 : undefined}
            {...valueProps}
          >
            {children(fieldProps)}
            <FormErrorMessage>
              {getNestedFormError(fieldProps.form.errors, name)}
            </FormErrorMessage>
          </VStack>
        </FormControl>
      )}
    </Field>
  );
};
