export enum ContactType {
  Home = "Home",
  Work = "Work",
  Postal = "Postal",
}

export const ContactTypeList = () =>
  Object.entries(ContactType).map(([label, value]) => {
    return { label: value, value: label };
  });
