import { Box, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import React, { useState } from "react";
import { CherryPayApi } from "../../api/models";
import { GeocodedAddress } from "../../components/fields/GeocodedAddress/GeocodedAddress";
import { TextField } from "../../components/fields/TextField/TextField";
import {
  CancelIcon,
  CheckIcon,
  EditIcon,
  SearchIcon,
} from "../../styles/icons";
import { MemberFormValues } from "./MemberForm";

interface MemberAddressFormProps {
  heading: string;
  addressFieldName: string;
  geocodedAddressFieldName: string;
  address?: CherryPayApi.Address | null;
  tempGeocodedAddress?: CherryPayApi.GeocodedAddress | null;
  setFieldValue: (field: string, value: any) => void;
}

export const MemberAddressForm = ({
  heading,
  addressFieldName,
  address,
  geocodedAddressFieldName,
  tempGeocodedAddress,
  setFieldValue,
}: MemberAddressFormProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const onSaveAddress = () => {
    setIsEditing(!isEditing);
    if (isEditing) {
      setFieldValue(geocodedAddressFieldName, tempGeocodedAddress);
    }
  };

  const onCancelAddress = () => {
    setIsEditing(false);
  };

  return (
    <Box
      w="100%"
      p={4}
      border="1px solid"
      borderColor="cherryButtonDark.50"
      borderRadius="md"
    >
      <HStack width="100%" mb={2}>
        <Text fontSize="sm" flex="1">
          <b>{heading}</b> :
        </Text>
        <HStack spacing={2}>
          <Button
            size="sm"
            leftIcon={isEditing ? <CheckIcon /> : <SearchIcon />}
            onClick={onSaveAddress}
            colorScheme="cherryButton"
          >
            {isEditing ? "Set" : "Search"}
          </Button>
          {isEditing && (
            <Button
              size="sm"
              leftIcon={<CancelIcon />}
              onClick={onCancelAddress}
              colorScheme="cherryButton"
            >
              Cancel
            </Button>
          )}
        </HStack>
      </HStack>
      <Divider mb={2} />

      {!isEditing && (
        <VStack w="100%" alignItems="flex-start">
          <TextField
            name={`${addressFieldName}.AddressLine1`}
            label="Address Line 1"
            isReadOnly
            backgroundColor="gray.100"
            color="gray.600"
          />
          <TextField
            name={`${addressFieldName}.AddressLine2`}
            label="Address Line 2"
            backgroundColor="gray.100"
            color="gray.600"
          />
          <TextField
            name={`${addressFieldName}.Suburb`}
            label="Suburb"
            backgroundColor="gray.100"
            color="gray.600"
          />
          <TextField
            name={`${addressFieldName}.State`}
            label="State"
            backgroundColor="gray.100"
            color="gray.600"
          />
          <TextField
            name={`${addressFieldName}.PostCode`}
            label="PostCode"
            backgroundColor="gray.100"
            color="gray.600"
          />
          <TextField
            name={`${addressFieldName}.Country`}
            label="Country"
            backgroundColor="gray.100"
            color="gray.600"
          />
        </VStack>
      )}

      {isEditing && (
        <GeocodedAddress
          name="tempGeocodedAddress"
          label="Geocoded Address"
          country="au"
        />
      )}
    </Box>
  );
};
